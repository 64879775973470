<template>
  <div>
    <b-card>
      <b-row>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Başlangıç Tarihi"
            label-for="sdate"
          >
            <b-form-datepicker
              id="sdate"
              v-model="filterData.sdate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Bitiş Tarihi"
            label-for="edate"
          >
            <b-form-datepicker
              id="edate"
              v-model="filterData.edate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Temas Tipi"
            label-for="id_com_meet"
          >
            <v-select
              id="id_com_meet"
              v-model="filterData.id_com_meet"
              label="title"
              :reduce="type => type.id"
              :options="meets"
              placeholder="Seçiniz"
              :close-on-select="false"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Lokasyon"
            label-for="id_com_location"
          >
            <v-select
              id="id_com_location"
              v-model="filterData.id_com_location"
              label="title"
              :reduce="type => type.id"
              :options="locations"
              placeholder="Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            variant="primary"
            :disabled="!filterData.sdate || !filterData.edate || !filterData.id_com_meet || !filterData.id_com_location"
            @click="getReport"
          >
            <FeatherIcon icon="PieChartIcon" />
            Raporu Görüntüle
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <pre-loading v-if="loading" />
    <template v-if="report.data.length > 0">
      <b-card
        v-for="(item) in report.data"
        :key="item.id"
        no-body
      >
        <b-card-header>
          <b-card-title>{{ item.brand }}</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row v-if="item.details.length > 0">
            <b-col
              v-for="(detail,key) in item.details"
              :key="key"
            >
              <div class="border p-2 text-center rounded bg-light">
                <div class="font-medium-3 font-weight-light text-nowrap">
                  {{ detail.service_status }}
                </div>
                <div class="font-weight-bolder font-large-1 text-primary">
                  {{ detail.count | toNumber }}
                </div>
              </div>
            </b-col>
          </b-row>
          <b-alert
            v-else
            show
          >
            <div class="alert-body text-center">
              Servis randevu verisi bulunmamaktadır.
            </div>
          </b-alert>
        </b-card-body>
        <b-table-simple
          v-if="item.interviews.length > 0"
          bordered
        >
          <b-thead>
            <b-tr>
              <b-th>
                Gelmeyen Müşteri Görüşme Durumları
              </b-th>
              <b-th class="text-center">
                Adet
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(interview,key) in item.interviews"
              :key="key"
            >
              <b-th>{{ interview.title }}</b-th>
              <b-td class="text-center">
                {{ interview.count | toNumber }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card>
    </template>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BFormDatepicker,
  BButton,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'ServiceTracing',
  components: {
    vSelect,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BFormDatepicker,
    BButton,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BAlert,
    PreLoading,
  },
  computed: {
    loading() {
      return this.$store.getters['reportServiceTracing/getLoading']
    },
    report() {
      return this.$store.getters['reportServiceTracing/getReport']
    },
    filterData() {
      return this.$store.getters['reportServiceTracing/getFilterData']
    },
    meets() {
      return this.$store.getters['meets/getMeets']
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    },
  },
  created() {
    this.getMeets()
    this.getLocations()
  },
  methods: {
    getReport() {
      this.$store.dispatch('reportServiceTracing/report', this.filterData)
    },
    getMeets() {
      this.$store.dispatch('meets/meetsList')
    },
    getLocations() {
      this.$store.dispatch('locations/locationsList')
    },
  },
}
</script>
